import { RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS, TOKEN_VERIFIED } from '@Store/auth/resetPassword/actionTypes'

const initialState = {
	resetSuccessMsg: null,
	resetError: null,
	accessToken: null,
}

const ResetPassword = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD:
			return {
				...state,
				resetSuccessMsg: null,
				resetError: null,
			}
		case RESET_PASSWORD_SUCCESS:
			if (action.payload.action === 'withdraw') {
				localStorage.setItem('action', 'withdraw')
			}
			return {
				...state,
				resetSuccessMsg: action.payload.message,
				accessToken: action.payload.token,
			}
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				resetError: action.payload
			}
		case TOKEN_VERIFIED:
			return {
				...state,
				readyRender: true,
				emailUser: action.payload.email,
				error: action.payload.error
			}
		default:
			return state

	}
}

export default ResetPassword
