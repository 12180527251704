import { RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS, TOKEN_VERIFIED, VERIFY_TOKEN, } from './actionTypes'

export const userResetPassword = (user, history, code, action) => {
	return {
		type: RESET_PASSWORD,
		payload: { user, history, code, action },
	}
}

export const userResetPasswordSuccess = (message, token, action) => {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: { message, token, action },
	}
}

export const userResetPasswordError = message => {
	return {
		type: RESET_PASSWORD_ERROR,
		payload: message,
	}
}

export const verifyToken = code => {
	return {
		type: VERIFY_TOKEN,
		payload: code,
	}
}

export const tokenVerified = ({ email, error }) => {
	return {
		type: TOKEN_VERIFIED,
		payload: { email, error },
	}
}
