import React from 'react'
import _ from 'lodash'

const currencyFormat = {
	USD: { locale: 'en-US', options: { currency: 'USD' } },
	EUR: { locale: 'en-GB', options: { currency: 'EUR' } },
	GBP: { locale: 'en-GB', options: { currency: 'GBP' } },
	CAD: { locale: 'en-CA', options: { currency: 'CAD' } },
	CHF: { locale: 'it-CH', options: { currency: 'CHF' } },
}

export function formatCurrency (number, currency, printDecimals = false, currencySmall = false) {
	if (isNaN(number)) return ''

	let locale = currencyFormat.EUR
	if (currencyFormat.hasOwnProperty(currency)) locale = currencyFormat[currency]

	locale.options.minimumFractionDigits = 0
	locale.options.maximumFractionDigits = 0

	if (printDecimals) {
		locale.options.minimumFractionDigits = 2
		locale.options.maximumFractionDigits = 2
	}
	let formatter = new Intl.NumberFormat(locale.locale, {
		...locale.options,
		style: 'currency',
	});

	let string = formatter.format(number)

	if (currencySmall) {
		const regex = /^([$€£¥a-zA-Z\s]*)([\d\.,]*)([$€£¥a-zA-Z\s]*)$/
		const parts = string.match(regex)
		const number = parts[2]
		if (!_.isEmpty(parts[1])) {
			string =
				<React.Fragment>
					<small>{parts[1]}</small>{number}
				</React.Fragment>

		} else {
			string =
				<React.Fragment>
					{number}<small>{parts[3]}</small>
				</React.Fragment>

		}
	}

	return string
}

export function formatBytes (bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function formatDate (date, separator = ':', hideIfZero = true, showDays = false) {
	if (hideIfZero) {
		if(showDays) {
			return (
				<React.Fragment>
					<span>{getTwoDigits(date.days)}</span>
					{separator}
					<span>{getTwoDigits(date.hours)}</span>
					{separator}
					<span>{getTwoDigits(date.minutes)}</span>
					{separator}
					<span>{getTwoDigits(date.seconds)}</span>
				</React.Fragment>)
		}
		return (
			<React.Fragment>
				<span>{getTwoDigits(date.hours + (24 * date.days))}</span>
				{separator}
				<span>{getTwoDigits(date.minutes)}</span>
				{separator}
				<span>{getTwoDigits(date.seconds)}</span>
			</React.Fragment>)
	}
	// TODO complete the function
	return 'incomplete'
}

function getTwoDigits (value) {
	return value < 10 ? '0' + value : value
}

export function numberFormat(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
