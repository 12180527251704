import { all, call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { RESET_PASSWORD, VERIFY_TOKEN } from './actionTypes'
import { tokenVerified, userResetPasswordError, userResetPasswordSuccess } from './actions'
import axios from 'axios'
import { handleError } from '@Store/helpers'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function * forgetUser ({ payload: { user, history, code, action } }) {
	try {
		const response = yield call(
			() => axios.post(
				`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/authentication/reset-password`,
				{ code, password: user.password }
			)
		)
		if (response) {
			yield put(
				userResetPasswordSuccess(
					'Password reset successfully',
					response.data.access_token,
					action
				)
			)
		}
	} catch (error) {
		yield put(userResetPasswordError(error.message))
	}
}

function * verifyTokenResetPassword ({ payload: code }) {
	try {
		const { data } = yield call(() => axios.get(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/public/login?code=${code}`))
		yield put(tokenVerified({ email: data }))
	} catch (error) {
		yield put(tokenVerified({ error: 'token not valid' }))
		handleError(error)
	}
}

function * resetPasswordSaga () {
	yield all([
		takeEvery(RESET_PASSWORD, forgetUser),
		takeEvery(VERIFY_TOKEN, verifyTokenResetPassword),
	])
}

export default resetPasswordSaga
