import { all, call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes'
import { userForgetPasswordError, userForgetPasswordSuccess } from './actions'
import axios from 'axios'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function * forgetUser ({ payload: { user, history } }) {
	try {
		const response = yield call(() => axios.post(`${process.env.REACT_APP_ENDPOINT_API_USER}/v1/authentication/forgot-password`, { email: user.email }))
		if (response) {
			yield put(userForgetPasswordSuccess('Reset link are sended to your mailbox, check there first'))
		}
	} catch (error) {
		yield put(userForgetPasswordError(error.message))
	}
}

function * forgetPasswordSaga () {
	yield all([
		takeEvery(FORGET_PASSWORD, forgetUser),
	])
}

export default forgetPasswordSaga
