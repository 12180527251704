import { ERROR_AXIOS_CALL, ERROR_AXIOS_CALL_400, ERROR_AXIOS_CALL_500, GENERIC_ERROR } from '@Store/system/actionTypes'

export const genericError = (message) => ({
	type: GENERIC_ERROR,
	payload: message
})

export const errorAxiosCall = (message) => ({
	type: ERROR_AXIOS_CALL,
	payload: message
})
export const errorAxiosCall400 = (message) => ({
	type: ERROR_AXIOS_CALL_400,
	payload: message
})
export const errorAxiosCall500 = (message) => ({
	type: ERROR_AXIOS_CALL_500,
	payload: message
})
